import React from 'react'
import Roadmap from '../../assets/roadmap.png'
import './services.css';
import Mentoring from '../../assets/mentoring.png'
import DigitalGrowth from '../../assets/digitalgrowth.png'

const Services = () => {
  return (
    <div className='services'>
     <div className='service'>
      <div className='content'>
       <span className='content-heading'>Business Roadmap</span>
       <p className='content-para'>We offer a customized strategic plan to guide your company towards long-term success. By analyzing market trends and identifying growth opportunities, we create detailed action plans, key milestones, and performance metrics to ensure you achieve your business objectives efficiently and effectively.
       </p>
       <div onClick={()=>{
        window.location="https://calendly.com/skysaurabh333/business-mentoring"
       }} className='call'>Book a Call</div>
      </div>         
         <img src={Roadmap} alt="RoadmapImg" className='serviceImg'/>
     </div>
     <div className='service'>
     <img src={Mentoring} alt="serviceImg" className='serviceImg'/>
     <div className='content'>
       <span className='content-heading'>One to One Mentoring</span>
       <p className='content-para'>We offer personalized guidance and support to help you navigate business challenges, develop essential skills, and achieve your professional goals. With tailored advice from experienced mentors, you'll gain the insights and confidence needed to drive your success.

       </p>
       <div onClick={()=>{
        window.location="https://calendly.com/skysaurabh333/business-mentoring"
       }} className='call'>Book a Call</div>
       
      </div>         
     </div>
     <div className='service'>
     <div className='content'>
       <span className='content-heading'>Digital Growth</span>
       <p className='content-para'>We help you expand your online presence and boost your business through effective digital marketing strategies.We focus on optimizing your digital channels, enhancing user engagement, and driving sustainable growth to maximize your online potential.
       </p>
       <div onClick={()=>{
        window.location="https://calendly.com/skysaurabh333/business-mentoring"
       }} className='call'>Book a Call</div>
      </div>         
         <img src={DigitalGrowth} alt="RoadmapImg" className='serviceImg'/>
     </div>
    </div>
  )
}

export default Services;