import React from 'react'
import './skills.css';
import Services from "../../components/Services/services";

const Skills = () => {
  return (
    <section id="skills">
       <span className='skillTitle'>
         What we do
       </span>
       <span className='skillDesc'>
       We Provide Exclusive Services For <span className='yourbussiness'>Your Business</span> 
       </span>
       <br/>
       <Services/>
    </section>
  )
}

export default Skills