import React from 'react'
import './portfolio.css'
import b2g from '../../assets/b2g.png'

const Portfolio = () => {
  return (
    <div className='portfolio'>
      <h1>Portfolio</h1>
      <div className='projects'>
      <div className='b2g-academy'>
        <a href="https://b2gacademy.in"><img className="b2g-image" src={b2g} alt="b2g"/>
          </a>
        <h2>B2G Academy</h2>
        <p><a href="https://b2gacademy.in">B2GAcademy.in</a> is a platform that helps navigate government contracts, offering resources and training to businesses aiming to succeed in the government contracting space.</p>
      </div>
      </div>
      
    </div>
  )
}

export default Portfolio;