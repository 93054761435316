import React,{useState} from 'react'
import './about.css'
import { Link } from 'react-scroll';

const About = () => {
  const [activeSite, setActiveSite] = useState('introduction');
  return (
    <div>
      <div className='heading'>
        <span className='heading-aboutus'>About Us</span>
      </div>
      <div className='aboutus-scroll'>
      <Link onClick={()=>{
        setActiveSite('introduction');
      }}activeClass="active" to="introduction" spy={true} smooth={true} offset={-100} duration={500}>
      <div className={`aboutus-scroll-box ${activeSite === 'introduction' ? 'actives' : ''}`}><span className={`aboutus-scroll-content ${activeSite === 'introduction' ? 'actived' : ''}`}>Introduction</span></div>
      </Link>
      <Link onClick={()=>{
        setActiveSite('whychooseus');
      }} activeClass="active" to="whychooseus" spy={true} smooth={true} offset={-100} duration={500}>
      <div className={`aboutus-scroll-box ${activeSite === 'whychooseus' ? 'actives' : ''}`}><span className={`aboutus-scroll-content ${activeSite === 'whychooseus' ? 'actived' : ''}`}>Why Choose Us?</span></div>
      </Link>
      <Link onClick={()=>{
        setActiveSite('ourmission');
      }} activeClass="active" to="ourmission" spy={true} smooth={true} offset={-100} duration={500}>
      <div className={`aboutus-scroll-box ${activeSite === 'ourmission' ? 'actives' : ''}`}><span className={`aboutus-scroll-content ${activeSite === 'ourmission' ? 'actived' : ''}`}>Our Mission</span></div>
      </Link>
      <Link onClick={()=>{
        setActiveSite('ourvision');
      }}activeClass="active" to="ourvision" spy={true} smooth={true} offset={-100} duration={500}>
      <div className={`aboutus-scroll-box ${activeSite === 'ourvision' ? 'actives' : ''}`}><span className={`aboutus-scroll-content ${activeSite === 'ourvision' ? 'actived' : ''}`}>Our Vision</span></div>
      </Link>
      <Link onClick={()=>{
        setActiveSite('contactus');
      }} activeClass="active" to="contactus" spy={true} smooth={true} offset={-100} duration={500}>
      <div className={`aboutus-scroll-box ${activeSite === 'contactus' ? 'actives' : ''}`}><span className={`aboutus-scroll-content ${activeSite === 'contactus' ? 'actived' : ''}`}>Contact Us</span></div>
      </Link>
      </div>

   <div className='aboutus-main-content'>
   <div className='introduction'>
        <h2 className='h2'>Introduction</h2>
        <p className='para'><span className='welcome'>Welcome to Reborise</span>, your go-to platform for business growth. We provide tailored solutions, including business roadmaps, one-on-one mentoring, website creation, and social media marketing, to help businesses thrive. Our secure and intuitive platform ensures that your operations are streamlined, and your data is protected at all times. Whether you're launching a startup or scaling an established business, Reborise is here to support your success.</p>
      </div>
      <div className='whychooseus'>
        <h2 className='h2'>Why Choose Us?</h2>
        <p className='para'>
        At <b>Reborise</b>, we understand the complexities businesses face in navigating growth and digital transformation. That’s why we’ve crafted our platform to be comprehensive, intuitive, and impactful.
  <ul style={{margin:"20px 30px"}}>
  <li>Strategic Business Roadmaps</li>
  <li>One-on-One Mentoring</li>
  <li>Manage Digital Presence</li>
  <li>Design User friendly websites</li>
   </ul>
        </p>
      </div>
      <div className='ourmission'>
        <h2 className='h2'>Our Mission</h2>
        <p className='para'>
        Our mission at Reborise is to equip businesses with the resources and insights they need to excel in a rapidly evolving market. We strive to simplify the complexities of business growth and digital transformation by offering innovative solutions that drive efficiency and success.
        <br/>
        <br/>

We understand that navigating growth can be complex and overwhelming, especially for businesses with limited resources. That's why we're committed to streamlining the process. Our platform is designed to make business management tasks easier, enabling companies to focus on what truly matters: delivering exceptional value to their customers.<br/><br/>

At Reborise, our goal is to make a meaningful impact on businesses of all sizes. We believe that every business, regardless of its scale or industry, deserves access to powerful growth strategies and digital tools. By helping businesses harness their potential, we aim to contribute to their success and create lasting value in the marketplace. Ultimately, our mission is about driving growth, fostering innovation, and supporting businesses on their journey to success.
        </p>
      </div>

      <div className='ourvision'>
        <h2 className='h2'>Our Vision</h2>
        <p className='para'>
        At <b>Reborise</b>, our vision is to transform the way businesses navigate growth and digital innovation. We envision a future where every business, regardless of size or industry, has access to advanced tools and strategic guidance that empower them to achieve their full potential.<br/><br/>

We aim to create an environment where business challenges are met with innovative solutions, and where each business can leverage its resources and insights to drive meaningful progress. Our vision is to build a community of forward-thinking businesses that are equipped to thrive in a dynamic market, continually evolving and achieving sustainable success.
        </p>
      </div>

      <div className='contactus'>
        <h2 className='h2'>Contact Us</h2>
        <p className='para'>
        For any questions or requests, don’t hesitate to reach out to us. Our team is here to assist you with any inquiries or support you may need.
        <br/>
        <span className='contactusclick' onClick={
          ()=>{
            window.location="./contactus"
          }
        }>Click Here</span>
        </p>
      </div>      

   </div>     
    </div>
  )
}

export default About;