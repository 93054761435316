import React, { useRef } from 'react'
import './contact.css'
import FacebookIcon from '../../assets/facebook-icon.png'
import TwitterIcon from '../../assets/twitter.png'
import YoutubeIcon from '../../assets/youtube.png'
import InstagramIcon from '../../assets/insta.png'
import PhoneIcon from '../../assets/contactlogo.png'
import EmailIcon from '../../assets/emaillogo.png'
import LinkedInIcon from '../../assets/linkedinlogo.png'
import emailjs from '@emailjs/browser';


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7dvfrvd', 'template_bpp7h2j', form.current, {
        publicKey: '-WnnUukE4f2mtW4mT',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
          alert('Email Sent!')
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <section id='contactPage'>
      <div id="experience">
        <h1 className='contactPageTitle'>Have An Idea In Mind?</h1>
        <p className='experienceDesc'>
        We’d love to hear from you! Whether you have a new project idea or need assistance with an existing one, our team is here to help. Reach out to us for expert guidance, innovative solutions, and personalized support. Let’s turn your vision into reality.
        </p>
        <div className='waysofcontacts'>
           <div className='waysofcontact'>
           <img src={PhoneIcon} alt="PhoneIcon" className='link'/>
           <div>
           <span>Call/Message:</span><br/>
           <span>+91 </span>
           </div>
           </div>

           <div className='waysofcontact'>
           <img src={EmailIcon} alt="EmailIcon" className='link'/>
           <div>
           <span>Mail Us:</span><br/>
           <span>reborise@gmail.com</span>
           </div>
           </div>

           <div className='waysofcontact'>
            <a href="https://www.linkedin.com/company/reborise/">
            <img src={LinkedInIcon} alt="LinkedInIcon" className='link'/>
            </a>
           <div>
           <span>Follow Us:</span><br/>
           <a href="https://www.linkedin.com/company/reborise/">
           <span>Reborise</span>
            </a>
           </div>
           </div>
        </div>
        <div>
          
        </div>
      </div>
      <div id="contact">
        <h1 className='contactPageTitle'>Contact Us</h1>
        <span className='contactDesc'>Please fill out the form below to discuss how we can support your business development needs and unlock new growth potential.</span>
        <form className='contactForm' ref={form} onSubmit={sendEmail}>
           <input type="text" className='name' placeholder='Your Name' name='your_name' required/>
           <input type="email" className='email' placeholder='Your Email' name='your_email' required/>
           <input type="tel" className='number' placeholder='Your Phone Number' name='your_number' required/>
           <textarea className='msg' name="message" rows="5" placeholder='Your Message' required></textarea>
           <button type="submit" value="send" className='submitBtn'>Submit</button>
           <div className='links'>
             <img src={FacebookIcon} alt="FacebookIcon" className='link'/>
             <img src={TwitterIcon} alt="TwitterIcon" className='link'/>
             <img src={YoutubeIcon} alt="YoutubeIcon" className='link'/>
             <a href='https://www.instagram.com/reborise/'><img src={InstagramIcon} alt="InstagramIcon" className='link'/></a>
           </div>
        </form>
      </div>
    </section>
  )
}

export default Contact;
