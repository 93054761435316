import Navbar from "./components/NavBar/navbar";
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Landing from "./Landing";
import Services from "./components/Services/services";
import About from "./components/About/about";
import Contact from "./components/Contact/contact";
import Insights from "./components/Insights/insights";
import whatsapp from './assets/whatsapplogo.png'
import './App.css';

function App() {
  return (
    <div style={{position:"relative"}} className="App">
      <Router>
      <Navbar/>
      <img alt="Whatsapp" onClick={()=>{
        window.location="https://chat.whatsapp.com/FlrGp1Rglra5NUvf1imEVt"
      }} className="whatsapp-image"src={whatsapp}/>
        <Routes>
          <Route path={"/"} element={<Landing/>}/>
          <Route path={"/services"} element={<Services/>}/>
          <Route path={"/about"} element={<About/>}/>
          <Route path={"/contactus"} element={<Contact/>}/>
          <Route path={"/insights"} element={<Insights/>}/>


        </Routes>
      </Router>
    </div>
  );
}

export default App;
